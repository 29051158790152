const routes = [{
        path: '/',
        component: () => import('@/view/index')
    },
    {
        path: '/index',
        component: () => import('@/view/index')
    }
];

export default routes