import {
  createApp
} from 'vue'
import App from './App.vue'
import router from './router/index'
import * as echarts from "echarts";
//引入element-plus模块
// import ElementPlus from 'element-plus'
// 引入字体样式
// import 'element-plus/dist/index.css'
// import "echarts-gl"; //3D地图插件
// import Highcharts from 'highcharts'
import vueSeamless from "vue-seamless-scroll/src";
// import Highcharts3d from 'highcharts/highcharts-3d'
// import VueParticles from 'vue-particles'
// import 'animate.css';
// Highcharts3d(Highcharts)
// 引入element
const vueMain = createApp(App)

//使用ElementPlus
// app.use(ElementPlus)
// app.mount('#app')
vueMain.config.globalProperties.$echarts = echarts
vueMain.use(router)
// vueMain.use(ElementPlus);
vueMain.use(vueSeamless);
// vueMain.use(VueParticles);
vueMain.mount('#app')
//js实现禁止页面拖拽图片
document.ondragstart = function () {
  return false;
};
//禁止用户选中页面的文字和禁止用户右键菜单
document.oncontextmenu = new Function("event.returnValue=false");
document.onselectstart = new Function("event.returnValue=false");
//禁止浏览器缩放
window.addEventListener('mousewheel', function (event) {
  if (event.ctrlKey === true || event.metaKey) {
    event.preventDefault();
  }
}, {
  passive: false
});

//firefox
window.addEventListener('DOMMouseScroll', function (event) {
  if (event.ctrlKey === true || event.metaKey) {
    event.preventDefault();
  }
}, {
  passive: false
});
document.addEventListener('touchmove', function (event) {
  if (event.touches.length > 1) {
    event.preventDefault();
  }
}, {
  passive: false
});
document.addEventListener('touchstart', function (event) {
  if (event.touches.length > 1) {
    event.preventDefault();
  }
}, {
  passive: false
});
document.addEventListener('touchend', function (event) {
  if (event.touches.length > 1) {
    event.preventDefault();
  }
}, {
  passive: false
})
export default vueMain